<template>
  <div class="coianter">
    <div class="idteail">
      <div class="idtname">注册信息</div>
      <div class="back">
        <i class="el-icon-close" @click="$router.go(-1)"></i>
      </div>
    </div>
    <div class="table">
      <el-form
        ref="form"
        :label-position="labelPosition"
        :model="form"
        label-width="150px"
      >
        <el-row :gutter="50">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi name_input">
                <el-form-item label="项目名称:">
                  {{ form.name }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="所属地区:">
                  {{ form.city }}
                </el-form-item>
                <div class="geziright">
                  <el-form-item label="所属区县:">
                    {{ form.county }}
                  </el-form-item>
                </div>
              </div>
              <div class="gezi">
                <el-form-item label="建筑面积（m²）:">
                  {{ form.building_area }}
                </el-form-item>
                <div class="geziright">
                  <el-form-item label="占地面积（m²）:">
                    {{ form.area }}
                  </el-form-item>
                </div>
              </div>
              <div class="gezi">
                <el-form-item label="测评阶段:">
                  {{ form.stage }}
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item label="测评单位:">
                  {{ form.test_company }}
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item label="测评联系人:">
                  {{ form.test_contact }}
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item label="测评联系人电话:">
                  {{ form.test_tel }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="测评时间:">
                  {{ form.test_time | formatDate("yyyy-MM-dd")}}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="能效测评报告： ">
             <a v-for="item in form.files" :key="item.name" :href="item.url" download="item.name">{{item.name}}</a>
                </el-form-item>
                <!-- <div class="fileright">
                  承诺函模板点击下载
                </div> -->
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <div class="gezi name_input">
                <el-form-item label="项目地址:">
                  {{ form.address }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="建筑类型:">
                  <span v-if="form.building_type == 1">公共建筑</span>
                  <span v-if="form.building_type == 2">居住建筑</span>
                </el-form-item>
                <div class="geziright">
                  <el-form-item label="建筑功能:">
                    <div v-if="form.building_type == 1">
                      <span v-if="form.building_ability == 1">办公建筑</span>
                      <span v-if="form.building_ability == 2">商场建筑</span>
                      <span v-if="form.building_ability == 3"
                        >宾馆饭店建筑</span
                      >
                      <span v-if="form.building_ability == 4"
                        >文化教育建筑</span
                      >
                      <span v-if="form.building_ability == 5">
                        医疗卫生建筑</span>
                         <span v-if="form.building_ability == 6">
                        其他建筑</span>
                    </div>
                    <div  v-if="form.building_type == 2">
                      <span v-if="form.building_ability == 1">住宅</span>
                      <span v-if="form.building_ability == 2">基地</span>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div class="gezi">
                <el-form-item label="层数:">
                  {{ form.floor }}
                </el-form-item>
                <div class="geziright">
                  <el-form-item label="气候区域:">
                    <span v-if="form.weather == 1">夏热冬冷</span>
                    <span v-if="form.weather == 2">寒冷</span>
                  </el-form-item>
                </div>
              </div>
              <div class="gezi">
                <el-form-item label="测评星级:">
                  <i
                    v-for="item in form.stars"
                    :key="item"
                    class="el-icon-star-on"
                  ></i>
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item label="建设单位:">
                  {{ form.build_ccompany }}
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item label="项目联系人:">
                  {{ form.contact }}
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item label="项目负责人:">
                  {{ form.manger }}
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item label="项目联系人电话:">
                  {{ form.contact_tel }}
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item label="能效测评报告编号:">
                  {{ form.number }}
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- <div class="comit">
      <div class="baocun">
        <el-button type="primary" @click="saveProject(21)">保存</el-button>
      </div>
      <div class="baocun">
        <el-button type="primary" @click="saveProject(20)" >提交</el-button>
      </div>
    </div> -->
  </div>
</template>
<script>
import { projectTableInfo, storeProject } from "../../../../api/project";
import JiangSu from "../../../../assets/jiangsu.json";

export default {
   filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      form: {
        stars: 1,
      },
      cityOptions: JiangSu.city,
      countyOptions: [],
      //公共建筑
      weatherOptions: [
        {
          name: "办公建筑",
          id: 1,
        },
        {
          name: "商场建筑",
          id: 2,
        },
        {
          name: "宾馆饭店建筑",
          id: 3,
        },
        {
          name: "文化教育建筑",
          id: 4,
        },
        {
          name: "医疗卫生建筑",
          id: 5,
        },
        {
          name: "其他建筑",
          id: 6,
        },
      ],
      //居住建筑
      juzhuOptions: [
        {
          name: "住宅",
          id: 1,
        },
        {
          name: "基地",
          id: 2,
        },
      ],
    };
  },
  mounted() {
    let project_id = this.$route.query.project_id;
    if (project_id && project_id != "") {
      this.project_id = project_id;
      this.getInfo();
    }
  },
  methods: {
    //获取详情
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        let file3 = [];
        let file2 = [];
        if (this.form.files && this.form.files.length > 0) {
          file3 = this.form.files
            .map((item) => {
              if (item.type == 3) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.fileList = file3;
        if (this.form.files && this.form.files.length > 0) {
          file2 = this.form.files
            .map((item) => {
              if (item.type == 2) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.photo = file2;
      });
    },
    //图片上传成功
    photo_success(re, file, fileList) {
      console.log(fileList);
    },
  fileUploadSuccess(response, file, fileList){
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length-1);
      this.fileList = tmpList.map(item =>{
        return item.response.data;
      })
      console.log(this.fileList);

    },
    //切换市
    cityChange(city) {
      for (let i = 0; i < this.cityOptions.length; i++) {
        if (this.cityOptions[i].name == city) {
          this.countyOptions = this.cityOptions[i].area;
          break;
        }
      }
    },

    //保存项目
    saveProject(status) {
      let params = this.form;
      params.token = this.$store.state.token;
      if (this.fileList && this.fileList.length > 0) {
        params.files = this.fileList.map((item) => {
          let res = item.response.data;
          res.type = 3;
          return res;
        });
      }
      params.status = status;
      let that = this;
      storeProject(params)
        .then((res) => {
          console.log(res);
          that.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    handleRemove(file) {
      console.log(file, "2");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file, "1");
    },
  },
};
</script>
<style scoped>
/deep/.el-upload-list{
      margin-top: -40px;
}
/deep/ .el-input {
  width: 250px;
}
</style>
<style lang="scss" scoped>
.coianter {
  background-color: #ffffff;
  // height: 100%;
}

.idteail {
  margin: 4px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #f4f4f4f4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.gezi {
  // margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .geziright {
    // margin-left: 20px;
  }

  .fileright {
    display: flex;
    flex-direction: row-reverse;
    color: #3086fb;
    font-size: 14px;
    cursor: pointer;
  }
}

.table {
  margin: 34px 100px;
}

.comit {
  padding: 94px 0;
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .baocun {
    margin: 0 25px;
  }
}
.name_input {
  width: 100%;
}
.name_input > .el-form-item {
  width: 100%;
}
.name_input > .el-form-item .el-input {
  width: 100%;
}
</style>
